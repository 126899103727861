window.addEventListener('turbolinks:load' , function() {
  if (document.querySelector('.orders.cart')) {
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    });

    const trashButtons = document.querySelectorAll('.trash-button');
    trashButtons.forEach((button) => {
      button.addEventListener('click', (e) => {
        if (window.confirm('Are you sure you want to delete this item from your cart?')) {
          const inputEl = e.target.closest('.line-item-row').querySelector('.quantity-input');
          inputEl.value = 0;
          updateOrder().then((response) => {
            deleteLineItem(inputEl);
            recalculateTotal();
            recalculateCartItemCount();
          });
        }
      });
    });

    const cartInputs = document.querySelectorAll('.quantity-input');
    cartInputs.forEach((input) => {
      input.addEventListener('input', (e) => {
        const inputEl = e.target;
        let inputValue = inputEl.value;
        if (inputValue === '') { return; }
        if (inputValue < 1) {
          inputValue = 1;
          inputEl.value = 1;
        }
        const quantity = parseInt(inputEl.value);
        const price = parseFloat(inputEl.dataset.price);
        const total = price * quantity;
        const totalEl = inputEl.closest('.line-item-row').querySelector('.line-item-total');
        totalEl.innerHTML = formatter.format(total);
        totalEl.dataset.total = total;
        updateOrder().then((response) => {
          recalculateTotal();
          recalculateCartItemCount();
        });
      });

      input.addEventListener('blur', (e) => {
        const inputEl = e.target;
        if (inputEl.value === '') {
          inputEl.value = 1;
          inputEl.dispatchEvent(new Event('input', { bubbles: true }));
        }
      });
    });

    const deleteLineItem = (inputEl) => {
      const row = inputEl.closest('.line-item-row').remove();
    }

    const updateOrder = () => {
      const orderForm = document.querySelector('.edit_order');
      const submitButton = document.querySelector('.btn--primary');
      const prevSubmitText = submitButton.innerHTML;
      submitButton.disabled = true;
      submitButton.innerHTML = 'Updating...';
      return fetch(orderForm.action, {
        method: orderForm.method,
        body: new FormData(orderForm),
      }).finally(() => {
        submitButton.innerHTML = prevSubmitText;
        submitButton.disabled = false;
      });
    }

    const recalculateTotal = () => {
      const lineItemTotals = document.querySelectorAll('.line-item-total');
      const total = Array.from(lineItemTotals).reduce((sum, total) => sum + Number(total.dataset.total), 0);
      const orderTotal = document.querySelector('.order-total');
      orderTotal.innerHTML = formatter.format(total);
    }

    const recalculateCartItemCount = () => {
      const quantityInputs = document.querySelectorAll('.quantity-input');
      const count = Array.from(quantityInputs).reduce((sum, quantityEl) => sum + parseInt(quantityEl.value), 0)
      const cartLinkCount = document.querySelector('.cart-link-count');
      if (count === 0) {
        const lineItemForm = document.querySelector('.edit_order');
        cartLinkCount.parentElement.removeChild(cartLinkCount);
        lineItemForm.parentElement.removeChild(lineItemForm);
      }
      cartLinkCount.innerHTML = count;
    }
  }
});
