const scriptId = 'ze-snippet';

document.addEventListener('turbolinks:before-cache', () => {
  // Clear the loaded flag
  window.zEACLoaded = false;
});

document.addEventListener('turbolinks:load', () => {
  window.zE && window.zE(function() {
    const zEscript = document.getElementById(scriptId);
    if (zEscript && !window.zEACLoaded) {
      zEscript.remove();
      reloadZendeskScript({scriptId, scriptSrc: zEscript.src});
    }
  });
});

const reloadZendeskScript = ({scriptId, scriptSrc}) => {
  const newScript = document.createElement('script');
  newScript.id = scriptId;
  newScript.src = scriptSrc;
  document.body.appendChild(newScript);
}
