document.addEventListener("DOMContentLoaded", function () {
  const $flash = document.querySelector(".flash");

  if ($flash) {
    const numSeconds = 30;
    const time1second = 1000;
    const duration = numSeconds * time1second;
    const timer = window.setTimeout(fadeOutFlash, duration);

    function fadeOutFlash() {
      $flash.style.opacity = "1";
      (function fade() {
        const fadeStep = 0.1;
        if (($flash.style.opacity -= fadeStep) < 0) {
          $flash.style.display = "none";
        } else {
          requestAnimationFrame(fade);
        }
      })();
      window.clearTimeout(timer);
    }
  }
});
