import CountryStates from 'js/CountryStates'
import mapOptions from './mapOptions'

window.addEventListener('turbolinks:load' , async function() {
  if (document.querySelector('.workshop_finder.index')) {
    const countryEl = document.querySelector('select#country');
    const stateSelectEl = document.querySelector('select#state');
    const stateInputEl = document.querySelector('input#state');
    new CountryStates(countryEl, stateSelectEl, stateInputEl).connectCountryAndState();
  }
  if (document.querySelector('.workshop_finder.search')) {
    const mapEl = document.getElementById('map');
    const searchTypeEl = document.querySelector('#search_type');
    const workshopTemplate = document.querySelector('.workshop-template');
    const countryEl = document.querySelector('select#country');
    const postalCodeEl = document.querySelector('#postal_code');
    const trainerTemplate = document.querySelector('.trainer-template');
    const infowindow = new google.maps.InfoWindow({ disableAutoPan : true });
    const map = new google.maps.Map(mapEl, mapOptions);
    const geocoder = new google.maps.Geocoder();
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const postalCode = urlParams.get('postal_code');
    const country = urlParams.get('country');
    const address = [postalCode, country].join(', ');
    const markers = [];

    [countryEl, postalCodeEl, searchTypeEl].forEach(el => {
      el.addEventListener('change', (e) => {
        const form = e.target.closest('form');
        form.submit();
      });
    });

    const geocode = async () => {
      try {
        const response = await geocoder.geocode({ address });
        if (response.results.length > 0) {
          map.setCenter(response.results[0].geometry.location);
        }
      }
      catch (error) {
        console.error("Error Geocoding Address: ", error);
      }
    }

    const clearMarkers = () => {
      markers.forEach((mark) => {
        mark.setMap(null);
      })
      markers.length = 0;
    }

    const showRegistration = (workshop) => {
      const registration = document.querySelector('.registration-modal');
      registration.classList.remove('hidden');
      document.querySelector('.registration-modal .location').textContent = workshop.location;
      document.querySelector('.registration-modal .date').textContent = workshop.date;
      document.querySelector('.registration-modal .time').textContent = workshop.time;
      document.querySelector('.registration-modal .trainer').textContent = workshop.trainer_name;
      document.querySelector('.registration-modal .cost').textContent = workshop.cost;
      document.querySelector('.registration-modal #workshop_registration_workshop_id').value = workshop.id;
      const form = document.querySelector('.registration-modal form');
      form.addEventListener('submit', (e) => {
        e.preventDefault();
        const form = e.target;
        const formData = new FormData(form);
        fetch(form.action, {
          method: 'POST',
          body: formData,
          headers: {
            'Accept': 'application/json',
            'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
          }
        }).then(response =>  response.json()).then((data) => {
          if (data.status === 'success') {
            form.reset();
            document.querySelector('.registration-modal .success').classList.remove('hidden');
            document.querySelector('.registration-modal .error').classList.add('hidden');
          } else {
            document.querySelector('.registration-modal .error').innerHTML = data.errors;
            document.querySelector('.registration-modal .error').classList.remove('hidden');
            document.querySelector('.registration-modal .success').classList.add('hidden');
            form.addEventListener('input', () => {
              document.querySelector('.registration-modal .error').classList.add('hidden');
              form.querySelector('input[type="submit"]').removeAttribute('disabled');
            });
          }
        });
      });
    }

    const buildWorkshop = (workshop, workHTML) => {
      workHTML.querySelector('.title').innerHTML = workshop.title;
      workHTML.querySelector('.date').innerHTML = workshop.date;
      workHTML.querySelector('.name').innerHTML = workshop.trainer_name;
      workHTML.querySelector('.cost').innerHTML = workshop.cost;
      workHTML.querySelector('.time').innerHTML = workshop.time;
      workHTML.classList.remove('hidden');
      workHTML.querySelector('.btn-details').addEventListener('click', (e) => {
        e.preventDefault();
        showWorkshopDetails(workshop);
      });
      return workHTML;
    }

    const buildTrainer = (trainer, trainerHTML) => {
      trainerHTML.querySelector('.title').innerHTML = trainer.title;
      trainerHTML.querySelector('.name').innerHTML = trainer.trainer_name;
      trainerHTML.querySelector('.phone').innerHTML = trainer.phone;
      trainerHTML.querySelector('.email').innerHTML = trainer.email;
      trainerHTML.querySelector('.website').innerHTML = trainer.website;
      trainerHTML.querySelector('.website').href = trainer.website;
      trainerHTML.classList.remove('hidden');
      trainerHTML.querySelector('.btn-details').addEventListener('click', (e) => {
        e.preventDefault();
        showTrainerDetails(trainer);
      });
      return trainerHTML;
    }

    const updateList = (workshops) => {
      const listEl = document.querySelector('.workshop-list');
      const listHTML = []
      listEl.innerHTML = '';
      workshops.forEach((work) => {
        if (work.type === 'workshop') {
          const workHTML = workshopTemplate.cloneNode(true);
          workHTML.id = `workshop-result-${work.id}`;
          listHTML.push(buildWorkshop(work, workHTML));
        }
        if (work.type === 'trainer') {
          const trainerHTML = trainerTemplate.cloneNode(true);
          trainerHTML.id = `trainer-result-${work.id}`;
          listHTML.push(buildTrainer(work, trainerHTML));
        }
      });
      listEl.append(...listHTML);
    }

    const showWorkshopDetails = (workshop) => {
      document.querySelector('.trainer-details').classList.add('hidden');
      document.querySelector('.workshop-details').classList.remove('hidden');
      document.querySelector('.workshop-details .date').innerHTML = workshop.date;
      document.querySelector('.workshop-details .time').innerHTML = workshop.time;
      document.querySelector('.workshop-details .cost').innerHTML = workshop.cost;
      document.querySelector('.workshop-details .location').innerHTML = workshop.formatted_address;
      document.querySelector('.workshop-details .trainer').innerHTML = workshop.trainer_name;
      document.querySelector('.workshop-details .phone').textContent = workshop.phone;
      document.querySelector('.workshop-details a.email').textContent = workshop.email;
      document.querySelector('.workshop-details a.email').href = `mailto:${workshop.email}`;
      document.querySelector('.workshop-details .registration-deadline').innerHTML = workshop.registration_deadline;
      document.querySelector('.workshop-details .payment-options').innerHTML = workshop.payment_options;
      document.querySelector('.workshop-details .additional-info').innerHTML = workshop.additional_info;
      document.querySelector('.workshop-details .trainer-bio').innerHTML = workshop.trainer_bio;

      const registrationButton = document.querySelector('.workshop-details .btn-register');
      const registrationClickHandler = (e) => {
        e.preventDefault();
        showRegistration(workshop);
      }

      // determine if the workshop is currently open for registration
      if (workshop.registration_deadline && workshop.start_datetime) {
        const timeZone = workshop.time_zone || 'UTC';
        const now = new Date();

        // Convert registration deadline to end of day in workshop's timezone
        const deadlineDate = new Date(workshop.registration_deadline);
        const deadlineEndOfDay = new Date(
          deadlineDate.getFullYear(),
          deadlineDate.getMonth(),
          deadlineDate.getDate(),
          23, 59, 59, 999
        );

        // Convert start datetime to workshop's timezone
        const startDateTime = new Date(workshop.start_datetime);

        // Compare using timestamps in the workshop's timezone
        const nowInTZ = new Date(now.toLocaleString('en-US', { timeZone }));
        const deadlineInTZ = new Date(deadlineEndOfDay.toLocaleString('en-US', { timeZone }));
        const startInTZ = new Date(startDateTime.toLocaleString('en-US', { timeZone }));

        const canRegister = (
          nowInTZ.getTime() <= deadlineInTZ.getTime() &&
          nowInTZ.getTime() <= startInTZ.getTime()
        );

        if (canRegister) {
          registrationButton.addEventListener('click', registrationClickHandler);
          registrationButton.classList.remove('hidden');
        } else {
          registrationButton.classList.add('hidden');
          registrationButton.removeEventListener('click', registrationClickHandler);
        }
      }
    }

    const showTrainerDetails = (trainer) => {
      document.querySelector('.workshop-details').classList.add('hidden');
      document.querySelector('.trainer-details').classList.remove('hidden');
      document.querySelector('.trainer-details .name').innerHTML = trainer.trainer_name;
      document.querySelector('.trainer-details .location').innerHTML = trainer.location;
      document.querySelector('.trainer-details .phone').textContent = trainer.phone;
      document.querySelector('.trainer-details .email').textContent = trainer.email;
      document.querySelector('.trainer-details .email').href = `mailto:${trainer.email}`;
      document.querySelector('.trainer-details .website').textContent = trainer.website;
      document.querySelector('.trainer-details .website').href = `${trainer.website}`;
      document.querySelector('.trainer-details .bio').innerHTML = trainer.trainer_bio;
    }

    const clearDetails = () => {
      document.querySelector('.workshop-details').classList.add('hidden');
      document.querySelector('.trainer-details').classList.add('hidden');
    }

    // Async method to wrap map.getBounds()
    // and return a Promise when it becomes ready,
    // which is difficult to pin down (hence the complexity here)
    const getMapBounds = async () => {
      return new Promise(resolve => {
        // First just check if we can getBounds immediately
        const bounds = map.getBounds();
        if (bounds) {
          resolve(bounds);
          return;
        }

        //Our first check failed, setting a listener for bounds_changed event
        console.log("Listening for bounds_changed event...");
        google.maps.event.addListenerOnce(map, 'bounds_changed', () => {
          let retries = 0;
          const tryGetBounds = () => {
            const bounds = map.getBounds();
            if (bounds) {
              // Got it!
              resolve(bounds);
            }
            else if (retries < 3) {
              retries++;
              console.log('Retrying map.getBounds(): ', retries);
              setTimeout(tryGetBounds, 200);
            }
            else {
              console.error('You have failed me for the last time, Admiral.');
              resolve(null);
            }
          };
          tryGetBounds();
        });

        // Now trigger a resize event so our bounds_changed handler gets hit
        if (map.getDiv().offsetWidth > 0) {
          google.maps.event.trigger(map, 'resize');
        }
      });
    };

    const getMarkers = async (fitBounds = true) => {
      clearMarkers();
      const mapBounds = await getMapBounds(); // Wait for this to work - may do retries and event waiting...
      const southWest = mapBounds.getSouthWest().toJSON();
      const northEast = mapBounds.getNorthEast().toJSON();
      const searchType = searchTypeEl.options[searchTypeEl.selectedIndex].value;
      const params = {
        south_west: Object.values(southWest),
        north_east: Object.values(northEast),
        search_type: searchType
      }
      const markerBounds = new google.maps.LatLngBounds();
      const url = new URL(window.location.origin + '/workshop_finder/markers')
      url.search = new URLSearchParams(params).toString();
      return fetch(url).then(response =>  response.json()).then((data) => {
        const workshops = data;
        if (workshops.length === 0) { return findNearestWorkshop(); }
        updateList(workshops);
        workshops.forEach((work) => {
          const marker = new google.maps.Marker({
            position: new google.maps.LatLng(work['latitude'], work['longitude']),
            map: map
          });
          markers.push(marker);

          markerBounds.extend(marker.position);

          function showMarker(marker) {
            const builder = {
              workshop: buildWorkshop,
              trainer: buildTrainer
            }[work.type];
            const template = {
              workshop: workshopTemplate,
              trainer: trainerTemplate
            }[work.type];
            const infoHTML = builder(work, template.cloneNode(true));

            return function() {
              infoHTML.classList.add('w-48', 'p-2');
              infoHTML.classList.remove('hidden');
              infowindow.setContent(infoHTML);
              infowindow.open(map, marker);
            }
          }

          google.maps.event.addListener(marker, 'click', showMarker(marker));
          document.getElementById(`${work.type}-result-${work.id}`).addEventListener('click', showMarker(marker));
        });

        if (fitBounds && markers.length > 0) {
          map.fitBounds(markerBounds);
          const zoom = map.getZoom();
          map.setZoom(zoom > 16 ? 16 : zoom);
        }
      });
    }

    document.querySelectorAll('.close-btn').forEach((el) => {
      el.addEventListener('click', (e) => {
        clearDetails();
      });
    });

    document.querySelector('.close-registration-btn').addEventListener('click', (e) => {
      document.querySelector('.registration-modal').classList.add('hidden');
    });

    const findNearestWorkshop = () => {
      const url = new URL(window.location.origin + '/workshop_finder/nearest')
      const searchType = searchTypeEl.options[searchTypeEl.selectedIndex].value;
      const params = {...map.getCenter().toJSON(), ...{ search_type: searchType }};
      url.search = new URLSearchParams(params).toString();
      fetch(url).then(response =>  response.json()).then((data) => {
        if (data.lat && data.lng) {
          map.setCenter(data);
        }
      });
    }

    // Synchronously load and set the map to the area we want based on the address param
    geocode();

    // Synchronously get all the markers in visible map area and put them on the map
    await getMarkers();

    // Now, any time map changes (scroll, zoom, pan), once it goes idle after update,
    // update the set of markers on the map to for the newly visible area.
    map.addListener('idle', () => {
      getMarkers(false);
    });
  }
});
