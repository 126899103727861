import braintree from 'braintree-web';
import thirdPartyPaymentAuthorized from 'js/thirdPartyPayment';

class PaypalButton {

  constructor(clientInstance, form) {
    this.clientInstance = clientInstance;
    this.form = form;
  }

  setPaypalBillingAddress(details, form) {
    form.querySelector('.new-card').disabled = true;
    form.querySelector('.billing-address').disabled = false;
    form.querySelector('.billing-address #address').value = details.billingAddress.line1;
    form.querySelector('.billing-address #address_cont').value = details.billingAddress.line2 || '';
    form.querySelector('.billing-address #city').value = details.billingAddress.city;
    form.querySelector('.billing-address #country').value = details.billingAddress.countryCode.toLowerCase();
    form.querySelector('.billing-address select#state').disabled = true;
    form.querySelector('.billing-address input#state').disabled = false;
    form.querySelector('.billing-address input#state').value = details.billingAddress.state;
    form.querySelector('.billing-address #postal_code').value = details.billingAddress.postalCode;
    form.first_name.value = details.firstName
    form.last_name.value = details.lastName;
  };

  handleButton() {
    const paypalButton = this.form.querySelector('#paypal-button');

    if (paypalButton) {
      braintree.paypal.create({
        client: this.clientInstance
      }, (paypalErr, paypalInstance) => {
        if (paypalErr) {
          console.error('Error creating PayPal:', paypalErr);
          return;
        }

        paypalButton.removeAttribute('disabled');

        // When the button is clicked, attempt to tokenize.
        paypalButton.addEventListener('click', (event) => {
          event.preventDefault();
          // Because tokenization opens a popup, this has to be called as a result of
          // customer action, like clicking a button. You cannot call this at any time.
          paypalInstance.tokenize({
            flow: 'vault'
            // For more tokenization options, see the full PayPal tokenization documentation
            // http://braintree.github.io/braintree-web/current/PayPal.html#tokenize
          }, (tokenizeErr, payload) => {
            if (tokenizeErr) {
              if (tokenizeErr.type !== 'CUSTOMER') {
                console.error('Error tokenizing:', tokenizeErr);
              }
              return;
            }

            // Tokenization succeeded
            paypalButton.setAttribute('disabled', true);
            paypalButton.classList.add('hidden');
            const nonce = payload.nonce;
            this.form.querySelector('#payment_nonce').value = nonce;

            this.setPaypalBillingAddress(payload.details, this.form);

            thirdPartyPaymentAuthorized('Paypal', paypalButton, this.form);
          });
        }, false);
      });
    }
  }
}

export default PaypalButton;
