import CountryStates from 'js/CountryStates'

window.addEventListener('turbolinks:load' , function() {
  if (document.querySelector('.facilitators.index')) {
    const form = document.querySelector('form#facilitators-search');
    const selects = document.querySelectorAll('form#facilitators-search select');
    const initAutoSubmit = (select) => {
      select.addEventListener('change', (e) => {
        form.submit();
      })
    }
    selects.forEach(initAutoSubmit);
  }

  if (document.querySelector('.facilitators.edit, .facilitators.settings')) {
    new CountryStates(
      document.querySelector('select[name="facilitator[country]"]'),
      document.querySelector('select[name="facilitator[state]"]'),
      document.querySelector('input[name="facilitator[state]"]')
    ).connectCountryAndState();
  }

  if (document.querySelector('.facilitators.complete_profile')) {
    new CountryStates(
      document.querySelector('select[name="facilitator[country]"]'),
      document.querySelector('select[name="facilitator[state]"]'),
      document.querySelector('input[name="facilitator[state]"]')
    ).connectCountryAndState();
  }
});
