import mapOptions from './mapOptions'

window.addEventListener('turbolinks:load' , async function() {
  if (document.querySelector('.facilitator_finder.search')) {
    const mapEl = document.getElementById('map');
    const countryEl = document.querySelector('#country');
    const religiousEl = document.querySelector('#religious_affiliation');
    const template = document.querySelector('.facilitator-template');
    const infowindow = new google.maps.InfoWindow({ disableAutoPan : true });
    const map = new google.maps.Map(mapEl, mapOptions);
    const geocoder = new google.maps.Geocoder();
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const postalCode = urlParams.get('postal_code');
    const country = urlParams.get('country');
    const address = [postalCode, country].join(', ');
    const markers = [];

    countryEl.addEventListener('change', (e) => {
      const form = e.target.closest('form');
      form.submit();
    });

    religiousEl.addEventListener('change', (e) => {
      const form = e.target.closest('form');
      form.submit();
    });

    const geocode = async () => {
      try {
        const response = await geocoder.geocode({ address });
        if (response.results.length > 0) {
          map.setCenter(response.results[0].geometry.location);
        }
      }
      catch (error) {
        console.error("Error Geocoding Address: ", error);
      }
    }

    const clearMarkers = () => {
      markers.forEach((mark) => {
        mark.setMap(null);
      })
      markers.length = 0;
    }

    const facilitatorContactUrl = (facilitator) => {
      return `https://www.prepare-enrich.com/facilitator-contact/?fid=${facilitator.slug}`;
    }

    const updateList = (facilitators) => {
      const listEl = document.querySelector('.facilitator-list');
      const listHTML = []
      facilitators.forEach((fac) => {
        const facHTML = template.cloneNode(true);
        facHTML.querySelector('.facilitator-image').src = fac.avatarUrl;
        facHTML.querySelector('.name').innerHTML = fac.name;
        facHTML.querySelector('.title').innerHTML = fac.title;
        facHTML.querySelector('.religious-affiliation').innerHTML = fac.religiousAffiliation;
        facHTML.querySelector('.facilitator-contact-btn').href = facilitatorContactUrl(fac);
        facHTML.querySelector('.facilitator-contact-btn').target = '_top';
        facHTML.id = `facilitator-result-${fac.id}`;
        facHTML.classList.remove('hidden');
        listHTML.push(facHTML.outerHTML);
      });
      listEl.innerHTML = listHTML.join('\n');
    }

    // Async method to wrap map.getBounds()
    // and return a Promise when it becomes ready,
    // which is difficult to pin down (hence the complexity here)
    const getMapBounds = async () => {
      return new Promise(resolve => {
        // First just check if we can getBounds immediately
        const bounds = map.getBounds();
        if (bounds) {
          resolve(bounds);
          return;
        }

        //Our first check failed, setting a listener for bounds_changed event
        console.log("Listening for bounds_changed event...");
        google.maps.event.addListenerOnce(map, 'bounds_changed', () => {
          let retries = 0;
          const tryGetBounds = () => {
            const bounds = map.getBounds();
            if (bounds) {
              // Got it!
              resolve(bounds);
            }
            else if (retries < 3) {
              retries++;
              console.log('Retrying map.getBounds(): ', retries);
              setTimeout(tryGetBounds, 200);
            }
            else {
              console.error('You have failed me for the last time, Admiral.');
              resolve(null);
            }
          };
          tryGetBounds();
        });

        // Now trigger a resize event so our bounds_changed handler gets hit
        if (map.getDiv().offsetWidth > 0) {
          google.maps.event.trigger(map, 'resize');
        }
      });
    };

    const getMarkers = async () => {
      clearMarkers();
      const mapBounds = await getMapBounds(); // Wait for this to work - may do retries and event waiting...
      const southWest = mapBounds.getSouthWest().toJSON();
      const northEast = mapBounds.getNorthEast().toJSON();
      const religiousAffiliation = religiousEl.options[religiousEl.selectedIndex].value;
      const params = {
        south_west: Object.values(southWest),
        north_east: Object.values(northEast),
        religious_affiliation: religiousAffiliation
      }
      const markerBounds = new google.maps.LatLngBounds();
      const url = new URL(window.location.origin + '/facilitator_finder/markers')
      url.search = new URLSearchParams(params).toString();
      return fetch(url).then(response =>  response.json()).then((data) => {
        const facilitators = data;
        if (facilitators.length === 0) { return findNearest(); }
        updateList(facilitators);
        facilitators.forEach((fac) => {
          const marker = new google.maps.Marker({
            position: new google.maps.LatLng(fac.lat, fac.lng),
            map: map
          });
          markers.push(marker);

          markerBounds.extend(marker.position);

          function showMarker(marker) {
            return function() {
              const facHTML = template.cloneNode(true);
              facHTML.querySelector('.facilitator-image').src = fac.avatarUrl;
              facHTML.querySelector('.name').innerHTML = fac.name;
              facHTML.querySelector('.title').innerHTML = fac.title;
              facHTML.querySelector('.religious-affiliation').innerHTML = fac.religiousAffiliation;
              facHTML.querySelector('.facilitator-contact-btn').href = facilitatorContactUrl(fac);
              facHTML.querySelector('.facilitator-contact-btn').target = '_top';
              facHTML.classList.add('w-48', 'p-2');
              facHTML.classList.remove('hidden');
              infowindow.setContent(facHTML);
              infowindow.open(map, marker);
            }
          }

          google.maps.event.addListener(marker, 'click', showMarker(marker));
          document.getElementById(`facilitator-result-${fac.id}`).addEventListener('click', showMarker(marker));
        });
      });
    }

    const findNearest = () => {
      const religiousAffiliation = religiousEl.options[religiousEl.selectedIndex].value;
      const url = new URL(window.location.origin + '/facilitator_finder/nearest')
      const params = {...map.getCenter().toJSON(), ...{ religious_affiliation: religiousAffiliation }};
      url.search = new URLSearchParams(params).toString();
      fetch(url).then(response =>  response.json()).then((data) => {
        if (data.lat && data.lng) {
          map.setCenter(data);
        }
      });
    }

    // Synchronously load and set the map to the area we want based on the address param
    geocode();

    // Synchronously get all the markers in visible map area and put them on the map
    await getMarkers();

    // Now, any time map changes (scroll, zoom, pan), once it goes idle after update,
    // update the set of markers on the map to for the newly visible area.
    map.addListener('idle', () => {
      getMarkers();
    });
  }
});
