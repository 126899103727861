import { calculateTaxFromElements } from './payment.js'
import CountryStates from 'js/CountryStates'
import { debounce } from './helpers.js'
import customSelect from 'custom-select';

window.addEventListener('turbolinks:load', function () {
  if (document.querySelector('.admin_orders.new')) {
    const debounceTime = 500;
    const form = document.querySelector('#payment-form');
    const submitButton = form.querySelector('input[type="submit"]');
    const countryEl = document.querySelector('select#order_shipping_country');
    const stateSelectEl = document.querySelector('select#order_shipping_state');
    const stateInputEl = document.querySelector('input#order_shipping_state');
    const postalCodeEl = document.querySelector('input#order_shipping_postal_code');
    const subtotalEl = document.querySelector('.order-summary .subtotal');
    const shippingEl = document.querySelector('#payment-form .order-summary .js-shipping');
    const formatter = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });

    const debouncedResetShipping = debounce(() => {
      resetShippingOptions();
    }, debounceTime);

    const debouncedCalculateTax = debounce(() => {
      calculateTaxFromElements({ countryEl, stateSelectEl, stateInputEl, postalCodeEl, subtotalEl, shippingEl });
    }, debounceTime);

    const areShippingOptionsValuesPresent = () => {
      return (stateSelectEl.value || stateInputEl.value) && countryEl.value && postalCodeEl.value;
    }

    const isCartEmpty = () => {
      const cartItemElements = document.querySelectorAll('select.product-select');
      return Array.from(cartItemElements).every((el) => el.value === '');
    }

    const calculateLineItemTotal = (el) => {
      const row = el.closest('tr');
      const priceText = row.querySelector('.price').textContent;
      const price = Number(priceText.replace(/[^0-9.-]+/g, ""));
      const quantity = Number(row.querySelector('.quantity').value);
      row.querySelector('.lineTotal').textContent = formatter.format(price * quantity);
      calculateTotal(true);
    }

    const calculateTotal = (resetShipping = false) => {
      const rows = document.querySelectorAll('#payment-form tbody tr.order-line-item');
      let subtotal = 0;
      rows.forEach((row) => {
        const lineTotalText = row.querySelector('.lineTotal').textContent;
        const lineTotal = Number(lineTotalText.replace(/[^0-9.-]+/g, ""));
        subtotal += lineTotal;
      });
      document.querySelector('#payment-form .order-summary .subtotal').textContent = formatter.format(subtotal);
      if (resetShipping && !isCartEmpty()) {
        resetShippingOptions();
      }
      calculateTaxFromElements({ countryEl, stateSelectEl, stateInputEl, postalCodeEl, subtotalEl, shippingEl });
    }

    const changePrice = (el) => {
      const row = el.closest('tr');
      const selectedPrice = el.options[el.selectedIndex].dataset.price;
      row.querySelector('.price').textContent = formatter.format(selectedPrice);
    }

    const addRemoveFieldListener = (el) => {
      el.addEventListener('click', function (event) {
        this.closest('tr').remove()
        calculateTotal(true);
        return event.preventDefault();
      });
    }

    const addProductSelectListener = (el) => {
      el.addEventListener('change', function (event) {
        changePrice(el);
        calculateLineItemTotal(el);
      });
    }

    const addQuantityListener = (el) => {
      el.addEventListener('change', function (event) {
        calculateLineItemTotal(el);
      });
    }

    const getShippingRates = async () => {
      return await fetch("/orders/shipping_rates", { method: 'POST', body: new FormData(form) })
        .then(response => response.json());
    }

    const resetShippingOptions = () => {
      const shippingMethodContainer = form.querySelector('.shipping-method');
      if (!areShippingOptionsValuesPresent()) {
        shippingMethodContainer.textContent = 'Fill in address to see shipping options.';
        return;
      }
      document.querySelector('#payment-form .order-summary .js-shipping').textContent = '$0.00';
      shippingMethodContainer.textContent = 'Getting shipping options...'
      getShippingRates().then((rates) => {
        if (!rates) { return; }
        if (Array.isArray(rates)) {
          const list = rates.map((rate) => {
            let rateHTML = shippingMethodContainer.dataset['shippingMethodRadioButton'];
            rateHTML = rateHTML.replaceAll('replacetitle', rate['title']);
            rateHTML = rateHTML.replaceAll('replaceamount', formatter.format(rate['priceV2']['amount']).replace('$', ''));
            rateHTML = rateHTML.replaceAll('replacehandle', rate['handle']);
            return rateHTML;
          });
          shippingMethodContainer.innerHTML = list.join('');
          shippingMethodContainer.querySelectorAll('input[type="radio"]').forEach((el) => {
            const label = document.querySelector(`label[for="${el.id}"]`);
            el.addEventListener('click', function (event) {
              const price = Number(label.textContent.replace(/[^0-9.]+/g, ""));
              document.querySelector('#payment-form .order-summary .js-shipping').textContent = formatter.format(price);
              calculateTotal();
            });
          });
        }
        else {
          let message = "Unknown error computing shipping rates. Please try again later or contact tech support.";
          if (rates['error']) {
            message = rates['error'];
          }
          shippingMethodContainer.textContent = message;
        }
      });
    };

    form.querySelector('.add_fields').addEventListener('click', function (event) {
      event.preventDefault();
      const time = new Date().getTime();
      const regexp = new RegExp(this.dataset.id, 'g');
      form.querySelector('tbody').insertAdjacentHTML('beforeend', this.dataset['fields'].replace(regexp, time));
      const row = form.querySelector('tbody').lastChild
      addRemoveFieldListener(row.querySelector('.remove_fields'));
      addProductSelectListener(row.querySelector('.product-select'));
      addQuantityListener(row.querySelector('.quantity'));
      const customSelects = customSelect(row.querySelectorAll('select.custom-select'));
      customSelects.forEach(window.initCustomSelect);
      return event.preventDefault();
    });

    form.querySelectorAll('.remove_fields').forEach((el) => {
      addRemoveFieldListener((el));
    });

    form.querySelectorAll('tr td .product-select').forEach((el) => {
      addProductSelectListener(el);
    });

    form.querySelectorAll('tr td input.quantity').forEach((el) => {
      addQuantityListener(el);
    });

    submitButton.addEventListener('click', (event) => {
      if (isCartEmpty()) {
        event.preventDefault();
        alert('Please add at least one item to the cart before submitting.');
      }
    });

    [stateSelectEl, stateInputEl, countryEl, postalCodeEl].forEach((element) => {
      ['change', 'input'].forEach((event) => {
        element.addEventListener(event, () => {
          if (isCartEmpty()) {
            return;
          }
          debouncedResetShipping();
          debouncedCalculateTax();
        });
      });
    });

    new CountryStates(countryEl, stateSelectEl, stateInputEl).connectCountryAndState();

    new CountryStates(
      document.querySelector('.invoice-billing-address select[name="country"]'),
      document.querySelector('.invoice-billing-address select[name="state"]'),
      document.querySelector('.invoice-billing-address input[name="state"]')
    ).connectCountryAndState();

    const billingAddress = document.querySelector('.billing-address');
    const newCard = document.querySelector('.new-card');
    const invoiceBillingAddress = document.querySelector('.invoice-billing-address');
    document.querySelector('#invoice_tab').addEventListener('click', (e) => {
      newCard.disabled = true;
      billingAddress.disabled = true;
      invoiceBillingAddress.disabled = false;
    });
    document.querySelector('#credit_card_tab').addEventListener('click', (e) => {
      newCard.disabled = false;
      billingAddress.disabled = false;
      invoiceBillingAddress.disabled = true;
    });
  }
});
