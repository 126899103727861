import _ from 'lodash';
import { calculateTaxFromElements } from 'js/payment';

const thirdPartyPaymentAuthorized = (paymentMethod, button, form) => {
  replaceButtonWithMessage(button, paymentMethod);
  disableOtherPaymentMethods(form, paymentMethod);
  disableCreditInput();

  if (!shouldRecalculateTax()) {
    return;
  }

  const taxElements = getTaxElements(form);
  calculateTaxFromElements(taxElements);
};

const createMessageElement = (authorizedMessage) => {
  const messageElement = document.createElement('span');
  const checkbox = document.createElement('i');
  checkbox.classList.add('fas', 'fa-check', 'mr-2');
  messageElement.textContent = authorizedMessage;
  messageElement.prepend(checkbox);
  messageElement.classList.add('flash', 'success');
}

const disableCreditInput = () => {
  const creditInputElSelector = '#num_credits';
  const creditInputEl = document.querySelector(creditInputElSelector);
  if (creditInputEl) {
    creditInputEl.readOnly = true;
  }
}

const disableOtherPaymentMethods = (form, paymentMethod) => {
  const radioButtonSelector = `.${_.kebabCase(paymentMethod)}-radio-button`;
  const tabSelector = `#${_.snakeCase(paymentMethod)}_tab`;
  const radioButton = form.querySelector(radioButtonSelector);

  radioButton.closest('ul').querySelectorAll('input').forEach((input) => {
    if (input.id !== tabSelector) {
      input.disabled = true;
    }
  });
}

const getTaxElements = (form) => {
  const countryElSelector = 'select#country';
  const stateSelectElSelector = 'select#state';
  const stateInputElSelector = 'input#state';
  const postalCodeElSelector = 'input#postal_code';
  const subtotalElSelector = '.js-subtotal';
  const shippingElSelector = '.order-summary .js-shipping';

  const countryEl = form.querySelector(countryElSelector);
  const stateSelectEl = form.querySelector(stateSelectElSelector);
  const stateInputEl = form.querySelector(stateInputElSelector);
  const postalCodeEl = form.querySelector(postalCodeElSelector);
  const subtotalEl = form.querySelector(subtotalElSelector);
  const shippingEl = form.querySelector(shippingElSelector);

  return { countryEl, stateSelectEl, stateInputEl, postalCodeEl, subtotalEl, shippingEl };
}

const replaceButtonWithMessage = (button, paymentMethod) => {
  const authorizedMessage = `Authorized with ${paymentMethod}`;
  const messageElement = createMessageElement(authorizedMessage);
  button.parentElement.prepend(messageElement);
  button.classList.add('hidden');
}

const shouldRecalculateTax = () => {
  /*
  Don't recalculate tax with new address if billing address already
  set on facilitator (i.e. on credits or group program checkout pages)
  */
  const creditsPageSelector = 'body.credits.index';
  const groupProgramPageSelector = 'body.group_program.pay';

  const isCreditsPage = document.querySelector(creditsPageSelector);
  const isGroupProgramPage = document.querySelector(groupProgramPageSelector);

  return !isCreditsPage && !isGroupProgramPage;
}

export default thirdPartyPaymentAuthorized;
