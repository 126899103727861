import updateOrderSummary from "js/orderSummary";

const fetchDataJSON = async (url) => {
  const response = await fetch(url);
  return await response.json();
};

const fetchNewTaxData = async (
  country,
  state,
  postalCode,
  subtotal,
  shipping
) => {
  const url = getTaxUrl(country, state, postalCode, subtotal, shipping);
  const data = await fetchDataJSON(url);

  if (data.error) {
    // TODO: capture error message
    return;
  }

  await updateOrderSummary({
    subtotal,
    shipping,
    amount: data.amount_to_collect,
    taxRate: data.rate,
  });
};

const getTaxUrl = (country, state, postalCode, subtotal, shipping) => {
  return (
    `/orders/taxes/?country=${country}&state=${state}` +
    `&postal_code=${postalCode}&amount=${subtotal}` +
    `&shipping=${shipping}`
  );
};

const isVisible = (element) => {
  return (
    element.offsetWidth > 0 ||
    element.offsetHeight > 0 ||
    element.getClientRects().length > 0
  );
};

const needsTaxCalculation = (country, state, postalCode) => {
  if (notUS(country)) {
    return false;
  }

  const validPostalCode = /^\d{5}$/.test(postalCode);
  const isTaxableState = ["MN", "WA"].includes(state);

  if (!validPostalCode || !isTaxableState) {
    return false;
  }

  return true;
};

const notUS = (country) => {
  return country.toUpperCase() !== "US";
};

export const calculateTax = async (taxArgs) => {
  const { country, state, postalCode, subtotal, shipping } = taxArgs;

  if (notUS(country)) {
    return;
  }

  if (!needsTaxCalculation(country, state, postalCode)) {
    updateOrderSummary({ subtotal, shipping });
  }

  await fetchNewTaxData(country, state, postalCode, subtotal, shipping);
};

export const calculateTaxFromElements = (taxElArgs) => {
  const {
    countryEl,
    stateSelectEl,
    stateInputEl,
    postalCodeEl,
    subtotalEl,
    shippingEl,
  } = taxElArgs;
  const country = countryEl.value;
  const state = isVisible(stateInputEl)
    ? stateInputEl.value
    : stateSelectEl.value;
  const postalCode = postalCodeEl.value;
  const subtotal = Number(subtotalEl.textContent.replace(/[^0-9.-]+/g, ""));
  const shipping = Number(shippingEl.textContent.replace(/[^0-9.-]+/g, ""));
  return calculateTax({ country, state, postalCode, subtotal, shipping });
};
