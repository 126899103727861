const getElementsToUpdate = (orderSummaryEl) => {
  return {
    taxEl: orderSummaryEl.querySelector(".js-tax"),
    shippingEl: orderSummaryEl.querySelector(".js-shipping"),
    totalEl: orderSummaryEl.querySelector(".js-total"),
  };
};

const getFormattedValues = (formatter, tax, shipping, total) => {
  return {
    tax: formatter.format(tax),
    shipping: formatter.format(shipping),
    total: formatter.format(total),
  };
};

const getFormatter = (orderSummaryEl) => {
  const currencyCode = orderSummaryEl.dataset.currencyCode || "USD";
  const locale = orderSummaryEl.dataset.locale || "en-US";
  return new Intl.NumberFormat(locale, {
    style: "currency",
    currency: currencyCode,
  });
};

const getTaxAmount = (subtotal, taxRate, amount) => {
  if (amount) {
    return parseFloat(amount);
  }
  return parseFloat(subtotal * taxRate);
};

const getTotal = (subtotal, tax, shipping) => {
  let total = parseFloat(subtotal) + tax;
  if (shipping) {
    total += shipping;
  }
  return total;
};

const updateOrderSummaryValues = (
  orderSummaryEl,
  elementsToUpdate,
  formattedValues,
  taxRate
) => {
  orderSummaryEl.dataset["taxRate"] = taxRate;
  elementsToUpdate.taxEl.textContent = formattedValues.tax;
  if (elementsToUpdate.shippingEl && formattedValues.shipping) {
    elementsToUpdate.shippingEl.textContent = formattedValues.shipping;
  }
  elementsToUpdate.totalEl.textContent = formattedValues.total;
};

const updateOrderSummary = ({
  subtotal,
  shipping,
  taxRate = 0,
  amount = 0,
}) => {
  const orderSummaryEl = document.querySelector(".order-summary");
  const elementsToUpdate = getElementsToUpdate(orderSummaryEl);

  const tax = getTaxAmount(subtotal, taxRate, amount);
  const total = getTotal(subtotal, tax, shipping);

  const formatter = getFormatter(orderSummaryEl);
  const formattedValues = getFormattedValues(formatter, tax, shipping, total);

  updateOrderSummaryValues(orderSummaryEl, elementsToUpdate, formattedValues, taxRate);

  return Promise.resolve();
};

export default updateOrderSummary;
