// TODO: Update this to use the JPY currency code
//       once we refactor the workshop currency enum
const JPY_CURRENCY_CODE = "ja-JP";
const JPY_DECIMAL_PLACES = 0;
const JPY_PLACEHOLDER = "0";
const DEFAULT_DECIMAL_PLACES = 2;
const DEFAULT_PLACEHOLDER = "0.00";

const initCurrencyFormatting = (currencyInputSelector, costInputSelector) => {
  const currencyInput = document.querySelector(currencyInputSelector);
  const costInput = document.querySelector(costInputSelector);
  const updateCostInputValue = () => {
    updateCostInputPlaceholder(currencyInput.value, costInput);
    if (costInput.value === "") return;
    costInput.value = formatCostValue(currencyInput.value, costInput.value);
  };
  currencyInput.addEventListener("change", updateCostInputValue);
  costInput.addEventListener("change", updateCostInputValue);
  costInput.addEventListener("keypress", maskNonNumericKeys);

  updateCostInputValue();
};

const updateCostInputPlaceholder = (currency, costInput) => {
  costInput.placeholder = getPlaceholder(currency);
};

const maskNonNumericKeys = (e) => {
  const charCode = e.which ? e.which : e.keyCode;
  // Check if the key pressed is not a number (0-9) or a decimal point
  // ASCII codes: 48-57 are numbers, 46 is decimal point, 31 and below are control characters
  if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
    e.preventDefault();
    return false;
  }
  return true;
};

const getPlaceholder = (currency) => {
  const isJPY = Boolean(currency === JPY_CURRENCY_CODE);
  return isJPY ? JPY_PLACEHOLDER : DEFAULT_PLACEHOLDER;
};

const formatCostValue = (currency, cost) => {
  const isJPY = Boolean(currency === JPY_CURRENCY_CODE);
  const decimalPlaces = isJPY ? JPY_DECIMAL_PLACES : DEFAULT_DECIMAL_PLACES;
  return roundToDecimalPlaces(cost, decimalPlaces);
};

const roundToDecimalPlaces = (number, decimalPlaces) =>
  Number.parseFloat(number).toFixed(decimalPlaces);

window.addEventListener("turbolinks:load", function () {
  if (
    document.querySelector("body.workshops.new") ||
    document.querySelector("body.workshops.edit")
  ) {
    const currencyInputSelector = "#currency";
    const costInputSelector = "#workshop_cost";
    if (
      !document.querySelector(currencyInputSelector) ||
      !document.querySelector(costInputSelector)
    ) {
      console.error("Currency input or cost input not found");
      return;
    }
    initCurrencyFormatting(currencyInputSelector, costInputSelector);
  }
});
