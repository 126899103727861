import CountryStates from 'js/CountryStates'

const countryStatePageSelectors = [
  '.workshops.trainer_application',
  '.trainer_applications.create',
  '.workshops.new', '.workshops.create',
  '.workshops.edit', '.workshops.update'
];

const autoSubmitPageSelectors = [
  '.workshops.index',
  '.facilitators.edit'
];

window.addEventListener('turbolinks:load' , function() {
  if (countryStatePageSelectors.some(selector => document.querySelector(selector))) {
    const countryEl = document.querySelector('select#country');
    const stateSelectEl = document.querySelector('select#state');
    const stateInputEl = document.querySelector('input#state');

    new CountryStates(countryEl, stateSelectEl, stateInputEl).connectCountryAndState();
  }

  if (autoSubmitPageSelectors.some(selector => document.querySelector(selector))) {
    const form = document.querySelector('form#js-workshop-filter');
    const selects = document.querySelectorAll('form#js-workshop-filter select');
    const initAutoSubmit = (select) => {
      select.addEventListener('change', (e) => {
        form.submit();
      })
    }
    selects.forEach(initAutoSubmit);
  }

  // Add new sorting functionality
  const registrantsTable = document.querySelector('.manage-table');
  if (registrantsTable) {
    const sortableHeaders = registrantsTable.querySelectorAll('th[data-sortable]');

    sortableHeaders.forEach(header => {
      // Add default sort icons to all sortable headers initially
      if (!header.querySelector('.sort-icons')) {
        header.classList.add('cursor-pointer');
        header.innerHTML = `
          <span class="sort-icons relative top-0.5 pr-4">
            <i class="fas fa-sort-up absolute top-0 bottom-0 text-gray-300 sort-icon-up"></i>
            <i class="fas fa-sort-down absolute top-0 bottom-0 text-gray-300 sort-icon-down"></i>
          </span>
          ${header.innerHTML}
        `;
      }

      header.addEventListener('click', () => {
        const tbody = registrantsTable.querySelector('tbody');
        const rows = Array.from(tbody.querySelectorAll('tr.data'));

        const currentDirection = header.dataset.direction || 'asc';
        const newDirection = currentDirection === 'asc' ? 'desc' : 'asc';

        // Reset all headers to default gray
        sortableHeaders.forEach(h => {
          h.dataset.direction = '';
          h.querySelectorAll('.sort-icon-up, .sort-icon-down').forEach(icon => {
            icon.classList.remove('text-blue-600');
            icon.classList.add('text-gray-300');
          });
        });

        // Update current header icons
        header.dataset.direction = newDirection;
        const activeIcon = header.querySelector(`.sort-icon-${newDirection === 'asc' ? 'up' : 'down'}`);
        activeIcon.classList.remove('text-gray-300');
        activeIcon.classList.add('text-blue-600');

        // Sort rows
        rows.sort((a, b) => {
          const aValue = a.querySelector(`td:nth-child(${header.cellIndex + 1})`).textContent.trim();
          const bValue = b.querySelector(`td:nth-child(${header.cellIndex + 1})`).textContent.trim();
          return newDirection === 'asc' ?
            aValue.localeCompare(bValue) :
            bValue.localeCompare(aValue);
        });

        // Reinsert rows in new order
        rows.forEach(row => tbody.appendChild(row));
      });
    });
  }
});
