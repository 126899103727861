// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import LocalTime from "local-time"
import 'flowbite';

import "channels"
import "js/advancedSearch"
import "js/CountryStates"
import "js/GooglePayButton"
import "js/PaypalButton"
import "js/adminOrders"
import "js/cart"
import "js/checkout"
import "js/coupleChildren"
import "js/couples"
import "js/credits"
import "js/currency"
import "js/debug"
import "js/dropdown"
import "js/facilitatorFinder"
import "js/facilitators"
import "js/flash"
import "js/loadingModal"
import "js/locale"
import "js/paywall"
import "js/phone"
import "js/promoCode"
import "js/radio"
import "js/select"
import "js/setup"
import "js/workshops"
import "js/workshopFinder"
import "js/zendesk"

global.$ = require("jquery");

Rails.start()
Turbolinks.start()
ActiveStorage.start()
LocalTime.start()
